<template>
  <div id="app">
    <div id="header">
      <b-row>
        <b-col>
          <h6>Nathan Ellis</h6>
        </b-col>

<!--        <b-col xl="6" lg="6" md="6" sm="12">-->
<!--          <b-row id="nav">-->
<!--            <b-col class="nav-item" @click="goto('about')">About</b-col>-->
<!--            <b-col class="nav-item" @click="goto('contact')">Contact</b-col>-->
<!--          </b-row>-->
<!--        </b-col>-->
      </b-row>
    </div>

    <About></About>
    <Contact></Contact>
    <Footer></Footer>
  </div>
</template>

<script>
import About from './components/About'
import Contact from './components/Contact'
import Footer from './components/Footer'

export default {
  name: 'App',
  components: {
    About,
    Contact,
    Footer
  },
  methods: {
    goto(refName) {
      let element = this.$el.querySelector("#" + refName);
      let top = element.offsetTop;

      window.scrollTo(0, top);
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap');

#header{
  background: #B91646;
  color: #FBF3E4;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  transition: padding-top 0.3s, padding-bottom 0.3s;
}

#nav{
  top: 50%;
  position: relative;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.nav-item{
  text-align: center;
  color: #FBF3E4;
  float: left;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  padding: 0 15px;
  margin: 0;
}

.nav-item:hover{
  transition: ease .5s;
  cursor: pointer;
}
</style>
