<template>
  <div id="contact">
    <b-row class="my-5">
      <b-col>
        <h6>Contact</h6>
      </b-col>
    </b-row>

    <b-row class="my-5">
      <b-col>
        <p>Please email me on: <a href="mailto:nathan@nathanellis.co.uk">nathan@nathanellis.co.uk</a></p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "Contact",
  data: () => ({
    form: {
      name: '',
      email: ''
    }
  }),
  methods: {
    onSubmit() {

    },
    onReset() {

    }
  }
}
</script>

<style scoped>
  #contact{
    background: #B91646;
    color: #FBF3E4;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    transition: padding-top 0.3s, padding-bottom 0.3s;
  }

  #contact a {
    color: #FBF3E4;
    text-decoration: none;
  }

  #contact a:hover {
    text-decoration: underline;
  }
</style>
