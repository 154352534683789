<template>
  <div id="footer">
    <b-row class="my-5">
      <b-col>
        <h6>Thank you</h6>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
  #footer{
    background: #4d4d4d;
    color: #6e6e6e;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    transition: padding-top 0.3s, padding-bottom 0.3s;
  }
</style>
