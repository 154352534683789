<template>
  <div id="about">
    <b-row class="my-5">
      <b-col>
        <img id='nathan' src="@/assets/img/nathan.jpg" alt="Hello">
      </b-col>
    </b-row>

    <b-row class="my-5">
      <b-col>
        <h6>Hi, I'm <span style="color: #B91646">Nathan Ellis</span>!</h6>
      </b-col>
    </b-row>

    <b-row class="my-5">
      <b-col>
        <b-icon icon="bug-fill" scale="3"></b-icon>
      </b-col>
    </b-row>

    <b-row class="my-5">
      <b-col>
        <p class="mb-3" style="font-weight: 500;">Manchester, UK</p>
        <p class="mb-3">Full Stack Developer</p>
        <p class="mb-3">AWS Developer Associate</p>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  name: "About"
}
</script>

<style scoped>
  #nathan{
    filter: grayscale(0%);
    width: 300px;
    border-radius: 50%;
    border: 1px solid #2C3E50;
  }

  #nathan:hover{
    filter: grayscale(100%);
    cursor: pointer;
    transition: ease 1s;
  }

  #about{
    font-weight: 300;
  }
</style>
